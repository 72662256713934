
function Footer() {

  const route = new URL(window.location.href).pathname
  console.log(route)

  const navItems = {
    '/projects': 'projects',
  }

  return (
    <div className="Footer">
      <nav className="flex flex-1 items-center">
        <div className="flex items-center font-semibold text-xl tracking-tight ml-6">
          <img src="./favicon.ico" alt="logo" className="max-w-5"></img>
          <a href="/" className="p-5">lmnd</a>
          <div className="flex">
            {Object.entries(navItems).map(([path, name]) => (
              <a
                key={ name }
                href={ path }
                className="p-5"
                style={{
                  textDecoration: route === path ? 'underline 2px var(--lmnd-primary)' : 'none',
                  textUnderlineOffset: '6px',                  
                }}
              > {name} </a>
            ))}
          </div>
        </div>
      </nav>
    </div>
  );
}

export default Footer;

import './App.css';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';

import Projects from './pages/Projects';
import Repeart from './pages/Repeart';
import Home from './pages/Home';
import TrollFace from './pages/Trollface';
import Pubkey from './pages/Pubkey';
import Footer from './components/Footer';

const hiddenRoutes = [
  "/id_rsa",
  "/id_rsa.pub",
];

export const routes = {
  // main, navbar items
  '/': Home,
  '/projects': Projects,
  '/repeart-demo': Repeart,

  // hidden
  '/id_rsa.pub': Pubkey,
  '/id_rsa': TrollFace,
  // '/cv': CurriculumVitae,
};


function App() {
  return (
    <Router>
      <div className="App bg-light">
        <Routes>
          {Object.entries(routes).map(([path, Component]) => (
            <Route key={path} path={path} element={<Component />} />
          ))}
        </Routes>
        {/* only include footer on certain pages */}
        {hiddenRoutes.includes(window.location.pathname) ? "" : <Footer />}
      </div>
    </Router>
  );
}


export default App;

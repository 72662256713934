function Projects() {

  const projects = [
    {
      name: 'lmnd.dev',
      href: new URL('/', window.location.href).toString(),
      year: 2024
    },
    { 
      name: 'repeart',
      href: new URL('/repeart-demo', window.location.href).toString(),
      year: 2023
    },
    {
      name: 'vue-bricks',
      href: new URL('https://bricks.lmnd.dev/').toString(),
      year: 2023
    },
  ]

  return (
    <div className="Projects my-auto min-h-[50%] md:m-auto md:max-w-[50%]">
      <div className="text-sm flex flex-auto flex-row flex-wrap content-start justify-center">
        {
          projects.map((item) => { 
            return (
              <div
                key={item.href}
                onClick={() => window.open(item.href, '_blank', 'noreferrer')}
                className="align-center flex h-10 hover:border-[darkgrey] border-b border-[lightgrey] p-2 w-full cursor-pointer transition duration-100 ease-in-out transform hover:translate-x-[-.1rem]"
              >
                <p className="min-w-[30%] font-semibold">{item.name}</p>
                <p className="underline">
                  {
                    item.href
                      .replace(/^https?:\/\//, '')
                      .replace(/\/$/, '')
                  }
                </p>
              </div>
            )
          })
        }
      </div>
    </div>
)
}

export default Projects;
